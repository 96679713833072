import { Button, Grid, TextField } from '@mui/material';
import * as React from 'react';
import { useHistory } from "react-router-dom";
import { BasePageStyles } from '../../hooks/styles';
import { IMAGES_URL } from '../../constants/Images';
import Api from '../../redux/lib/api';
import { LOGIN } from '../../redux/constants/api.url';

interface LoginProps {}

export const LoginComponent: React.FC<LoginProps> = (props) => {
	const classes = BasePageStyles();
	const history = useHistory(); 

	const [username, setUsername] = React.useState<string>('');
	const [password, setPassword] = React.useState<string>('');

	const routeChange = (path: string) =>{ 
	 	history.push(path);
	 }

	const login = async () => {
		let response;

		try {
			response = await Api.post(LOGIN.LOGIN, {
				username: username,
				password: password
			});
		}
		catch(e) {
			alert('error logging in');
		}

		if (response?.data?.success) {
			localStorage.setItem('iafq', 'true');
			routeChange('/');
		}
		else {
			alert('error logging in');
		}
	}

	return (
		<Grid container item textAlign={'center'} xs={12} md={6}>
			<Grid item xs={12}>
				<div style={{
						marginBottom: 20
					}}
				>
					<TextField
						label="User name"
						variant="outlined"
						value={username}
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
							setUsername(event.target.value);
						}}
						required
					/>
				</div>
				<div style={{
						marginBottom: 20
					}}
				>
					<TextField
						label="Password"
						variant="outlined"
						value={password}
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
							setPassword(event.target.value);
						}}
						required
						type="password"
					/>
				</div>
				<div style={{
						marginBottom: 20
					}}
				>
					<Button 
						variant="contained"
						onClick={login}
						disabled={Boolean(!username || !password)}
					>
						Login
					</Button>
				</div>
			</Grid>
		</Grid>
	)
}