import config from '../../config';

export const API_BASE_URL = {
	API_GATEWAY: config.apiGateway,
};

export const ORDER = {
	ORDERS: `${API_BASE_URL.API_GATEWAY}/orders`,
	GET_TAX: `${API_BASE_URL.API_GATEWAY}/tax`,
	VALIDATE_ADDRESS: `${API_BASE_URL.API_GATEWAY}/tax/validateAddress`,
	VALIDATE_ADDRESS_LIST: `${API_BASE_URL.API_GATEWAY}/tax/validateAddresses`,
	PRODUCT: `${API_BASE_URL.API_GATEWAY}/products`,
	GET_CC_PAYMENT_TOKEN: `${API_BASE_URL.API_GATEWAY}/ccPaymentToken`,
	VALIDATE_DISCOUNT_CODE: `${API_BASE_URL.API_GATEWAY}/validateDiscountCode` 
};

export const LOGIN = {
	LOGIN: `${API_BASE_URL.API_GATEWAY}/login`
}
