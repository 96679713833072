import { Redirect, Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { connect } from "react-redux";

import { themeNames } from "../hooks/styles/muiTheme";
import { getMuiTheme } from "../hooks/styles";
import { CssBaseline, StyledEngineProvider, ThemeProvider } from "@mui/material";
import { default as GlobalSnackbar } from '../components/snackbar';
import Order from "../pages/order";
import Payment from "../pages/payment";
import Product from "../pages/product";
import Login from "../pages/login";
import config from "../config";
import React from "react";

const Routes = (props: any) => {
  return (
    <ThemeProvider theme={getMuiTheme(props.selectedTheme || themeNames.LIGHT)}>
      <StyledEngineProvider injectFirst>
        <CssBaseline>
			  	<GlobalSnackbar />
          <Router>
            <Switch>
              <PrivateRoute exact path="/">
                <Product />
              </PrivateRoute>
              <PrivateRoute exact path="/order">
                <Order />
              </PrivateRoute>
              <PrivateRoute exact path="/payment">
                <Payment />
              </PrivateRoute>
              <Route exact path="/login">
                <Login />
              </Route>
            </Switch>
          </Router>
        </CssBaseline>
      </StyledEngineProvider>
    </ThemeProvider>
  )
}

const mapStateToProps = (state: any) => ({
  dataIsPreloaded: state.ui.dataIsPreloaded,
  orderDetails: state.order.orderDetails || {},
})
const mapDispatchToProps = (dispatch: any) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Routes);


interface PrivateRouteProps extends React.HTMLAttributes<Element> {
  children?: React.ReactNode, 
  exact?: any,
  path: any,
  component?: any
}

const PrivateRoute = ({ children, ...rest }: PrivateRouteProps) => {
  return (
    <Route
      {...rest}
      render={() => {
        return isAuthed() === true ? (
          children
        ) : (
          <Redirect to="/login" />
        );
      }}
    />
  );
}

const isAuthed = () => {
  if (config.environment === 'local' || config.environment === 'qa') {
    console.log('checking localstorage for auth')
    //test if auth already done and stored in local storage 
    if (localStorage.getItem('iafq') === 'true') {
      return true
    }
    else {
      return false
    }

  }
  else {
    console.log('no auth required')
    return true
  }
  
}








