import { connect } from 'react-redux';
import { LoginComponent } from './login.component';

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = (dispatch: any) => ({});

export const LoginContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginComponent);