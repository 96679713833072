import { connect } from 'react-redux';
import { ProductComponent } from './product.component';
import { OrderActions } from '../../redux/actions';

const mapStateToProps = (state: any) => ({
  orderQuantity: state.order.orderQuantity || 6,
  products: state.order.products || [],
});

const mapDispatchToProps = (dispatch: any) => ({
  setOrderQuantity: (orderQuantity: number) => dispatch(OrderActions.setOrderQuantity(orderQuantity)),
  setValidatedTaxResult: (newVal: any) => dispatch(OrderActions.setValidatedTaxResult(newVal)),
  setValidatedAddresses: (newVal: any) => dispatch(OrderActions.setValidatedAddresses(newVal)),
  clearTaxRate: () => dispatch(OrderActions.clearTaxRate()),
});

export const ProductContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductComponent);